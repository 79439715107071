import { template as template_30ed9767bfc74727aa670080d94f9a44 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_30ed9767bfc74727aa670080d94f9a44(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
