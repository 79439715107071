import { template as template_0ac37d11739541c5b881133d365bb3aa } from "@ember/template-compiler";
const FKText = template_0ac37d11739541c5b881133d365bb3aa(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
